// src/components/Layout/Section.tsx

import classNames from 'classnames';
import {FC, PropsWithChildren} from 'react';

import {SectionId} from '../../data/data';

interface SectionProps {
  sectionId: SectionId;
  sectionTitle?: string;
  noPadding?: boolean;
  className?: string;
}

const Section: FC<PropsWithChildren<SectionProps>> = ({children, sectionId, noPadding = false, className, sectionTitle}) => {
  return (
    <section
      className={classNames(className, {'px-4 py-16 md:py-24 lg:px-8': !noPadding})}
      id={sectionId}
    >
      <div className={classNames({'mx-auto max-w-screen-lg': !noPadding})}>
        {sectionTitle && <h2 className="text-2xl font-bold mb-8">{sectionTitle}</h2>}
        {children}
      </div>
    </section>
  );
};

export default Section;
