// _app.tsx
import '../src/GlobalStyles.scss';

import type {AppProps} from 'next/app';
import {appWithTranslation} from 'next-i18next';

function MyApp({Component, pageProps}: AppProps): React.ReactElement {
  return <Component {...pageProps} />;
}

export default appWithTranslation(MyApp);
