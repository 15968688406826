// src/components/Layout/Page.tsx

import {NextPage} from 'next';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {PropsWithChildren} from 'react';

import {HomepageMeta} from '../../data/dataDef';

const Page: NextPage<PropsWithChildren<HomepageMeta>> = ({children, title, description}) => {
  const router = useRouter();
  const canonicalUrl = `https://theodadon.fr${router.asPath}`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />

        {/* Lien canonique */}
        <link href={canonicalUrl} rel="canonical" />

        {/* Balises Open Graph */}
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content={canonicalUrl} property="og:url" />
        <meta content="website" property="og:type" />

        {/* Twitter Card */}
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={title} name="twitter:title" />
        <meta content={description} name="twitter:description" />
      </Head>
      {children}
    </>
  );
};

export default Page;
