import {ChevronDownIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {useTranslation} from 'next-i18next';
import {FC} from 'react';

import {heroData, SectionId} from '../../data/data';
import Section from '../Layout/Section';
import Socials from '../Socials';

const Hero: FC = () => {
  const {actions, imageSrc} = heroData;
  const {t} = useTranslation('common');
  const backgroundImageUrl = typeof imageSrc === 'string' ? imageSrc : imageSrc?.src;

  return (
    <Section noPadding sectionId={SectionId.Hero}>
      <div
        className="relative flex h-screen w-full items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="z-10 max-w-screen-lg px-4 lg:px-0">
          <div className="flex flex-col items-center gap-y-6 rounded-xl bg-gray-800/40 p-6 text-center shadow-lg backdrop-blur-sm">
            <h1 className="text-4xl font-bold text-white sm:text-5xl lg:text-7xl">{t('hero.name')}</h1>
            <strong className="text-stone-100">{t('hero.description.line1')}</strong>
            <div className="flex gap-x-4 text-neutral-100">
              <Socials />
            </div>
            <div className="flex w-full justify-center gap-x-4">
              {actions.map(({id, primary, Icon}) => (
                <a
                  className={classNames(
                    'flex gap-x-2 rounded-full border-2 bg-none px-4 py-2 text-sm font-medium text-white ring-offset-gray-700/80 hover:bg-gray-700/80 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-base',
                    primary ? 'border-orange-500 ring-orange-500' : 'border-white ring-white'
                  )}
                  download={id === 'resume' ? true : undefined}
                  href={id === 'contact' ? `/#${SectionId.Contact}` : '/cv.pdf'}
                  key={id}
                >
                  {t(`hero.actions.${id}`)}
                  {Icon && <Icon className="h-5 w-5 text-white sm:h-6 sm:w-6" />}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-6 flex justify-center">
          <a
            aria-label="Scroll Down"
            className="rounded-full bg-white p-1 ring-white ring-offset-2 ring-offset-gray-700/80 focus:outline-none focus:ring-2 sm:p-2"
            href={`/#${SectionId.About}`}
          >
            <ChevronDownIcon className="h-5 w-5 bg-transparent sm:h-6 sm:w-6" />
          </a>
        </div>
      </div>
    </Section>
  );
};

export default Hero;
